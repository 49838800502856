import React, { useState } from 'react';

const underway = [
  {
    title: 'CashFusion',
    desc: 'opt-in privacy',
  },
  {
    title: 'Avalanche Pre-consensus',
    desc: 'instant transactions & real-time processing',
  },
  {
    title: 'Reference Indexer',
    desc: 'programmatic block explorer',
  },
];

const planned = [
  {
    title: 'Faster Block Propagation',
    desc: 'graphene or other',
  },
  {
    title: 'Advanced Opcodes',
    desc: 'enhanced script capability',
  },
  {
    title: 'UTXO Commitment',
    desc: 'blockchain pruning, faster initial sync',
  },
  {
    title: 'Merklix-Metadata Tree',
    desc: 'scalable block processing',
  },
  {
    title: 'Adaptive Block Size',
    desc: 'market driven growth to 1TB blocks',
  },
  {
    title: 'Zero-Knowledge Subchain',
    desc: 'bulletproof privacy',
  },
  {
    title: 'Fractional Satoshis',
    desc: 'fees low forever',
  },
  {
    title: 'EVM Subchain',
    desc: 'scalable smart contracts & improved privacy',
  },
  {
    title: 'New Transaction Format',
    desc: 'more capable & compact',
  },
];

const ComingSoon = () => {
  const [plannedlist, setPlanned] = useState(false);
  const plannedlength = planned.length;
  return (
    <div className="coming-soon-ctn">
      <h3>What's Coming</h3>
      <div className="modal-ctn">
        <div className="modal-ctn-col">
          <h4 style={{ color: '#ff21d0' }}>Underway</h4>
          <div className="modal-border">
            <div className="modal-inner">
              {underway.map((underway) => {
                return (
                  <div className="modal-row">
                    <h2>{underway.title}</h2>
                    <div>{underway.desc}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="modal-ctn-col">
          <h4 style={{ color: '#0074c2' }}>Planned</h4>
          <div className="modal-border modal-border2">
            <div className="modal-inner">
              {planned.slice(0, 4).map((underway) => {
                return (
                  <div className="modal-row">
                    <h2>{underway.title}</h2>
                    <div>{underway.desc}</div>
                  </div>
                );
              })}
              {plannedlist &&
                planned.slice(4, plannedlength).map((underway) => {
                  return (
                    <div className="modal-row">
                      <h2>{underway.title}</h2>
                      <div>{underway.desc}</div>
                    </div>
                  );
                })}
              <div className="show-more-ctn">
                <div
                  className="show-more"
                  onClick={() => setPlanned(!plannedlist)}
                >
                  {plannedlist === false ? 'Show more' : 'Show less'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
